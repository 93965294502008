<template>
  <div class="review-container">
    <div class="review-header">
      <div class="head">
        REVIEW
      </div>
      <div class="sub">
        รีวิวจากลูกค้าของเรา
      </div>
    </div>
    <div class="review-menu box-wrapper mt-3">
      <div class="row m-0">
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ path: '/review', hash: '#review-1' }">
            <div>
              ดูดไขมันหน้าท้อง
            </div>
          </router-link>
        </div>
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ name: 'Review', hash: '#review-2' }">
            <div>
              เติมไขมันหน้าเด็ก
            </div>
          </router-link>
        </div>
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ name: 'Review', hash: '#review-3' }">
            <div>
              ดูดไขมันแขน
            </div>
          </router-link>
        </div>
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ name: 'Review', hash: '#review-4' }">
            <div>
              Hifu
            </div>
          </router-link>
        </div>
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ name: 'Review', hash: '#review-5' }">
            <div>
              ดูดไขมันขา
            </div>
          </router-link>
        </div>
        <div class="col-6 col-sm-4 col-md-3 menu-item">
          <router-link :to="{ name: 'Review', hash: '#review-6' }">
            <div>
              PRP
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="review-content box-wrapper">
      <div
        v-for="(item, key) in review"
        :id="'review-' + (key + 1)"
        :key="key"
        class="content-wrapper"
      >
        <div id="head" class="content-title">
          {{ item.title }}
        </div>
        <div class="content-body">
          <swiper :options="swiperOption">
            <!-- <swiper-slide>Slide 1</swiper-slide> -->
            <swiper-slide v-for="(src, index) in item.imgRange" :key="index">
              <viewer :images="item.imgRange" class="row m-0">
                <img
                  class="w-100 col-3 img-item"
                  :src="require(`@images/review/${item.imgSource}/${src}`)"
                  :data-src="src"
                />
              </viewer>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination"></div>
            <div slot="button-prev" class="swiper-button-prev"></div>
            <div slot="button-next" class="swiper-button-next"></div>
          </swiper>
        </div>
      </div>
      <div
        data-token="Iyb6vZDd6QEOwQ6ep8OqhuGt1oGmziGNWe0iRZqnRG2evgltLp"
        class="romw-reviews"
      ></div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
export default {
  name: "Review",
  metaInfo: {
    title: "รีวิว",
    meta: [
      {
        name: "og:title",
        content: "รีวิว | Revealing Clinic"
      }
    ]
  },
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 30,
        dragable: true,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },

        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerColumn: 1
          },
          992: {
            slidesPerView: 2,
            slidesPerColumn: 1
          },
          1500: {
            slidesPerView: 3,
            slidesPerColumn: 2
          }
        }
      },
      review: [
        {
          title: "รีวิว ดูดไขมันหน้าท้อง",
          routeName: "ServiceSub",
          routeParam: { subname: "fat" },
          imgSource: "back",
          imgRange: [...Array(27).keys()]
            .map(item => `${item + 1}.jpg`)
            .reverse()
        },
        {
          title: "รีวิว เติมไขมันหน้าเด็ก",
          routeName: "ServiceSub",
          routeParam: { subname: "fat" },
          imgSource: "face",
          imgRange: [...Array(12).keys()].map(item => `${item + 1}.jpg`)
        },
        {
          title: "รีวิว ดูดไขมันแขน",
          routeName: "ServiceSub",
          routeParam: { subname: "fat" },
          imgSource: "hand",
          imgRange: [...Array(26).keys()]
            .map(item => `${item + 1}.jpg`)
            .reverse()
        },
        {
          title: "รีวิว Hifu",
          routeName: "ServiceSub",
          routeParam: { subname: "hifu" },
          imgSource: "hifu",
          imgRange: [...Array(13).keys()].map(item => `${item + 1}.jpg`)
        },
        {
          title: "รีวิว ดูดไขมันขา",
          routeName: "ServiceSub",
          routeParam: { subname: "fat" },
          imgSource: "leg",
          imgRange: [...Array(17).keys()]
            .map(item => `${item + 1}.jpg`)
            .reverse()
        },
        {
          title: "รีวิว PRP",
          routeName: "ServiceSub",
          routeParam: { subname: "collagen" },
          imgSource: "prp",
          imgRange: [...Array(3).keys()].map(item => `${item + 1}.jpg`)
        }
      ]
    };
  }
  // beforeRouteEnter(to, from, next) {
  //   if (to.hash) {
  //     this.$nextTick(function() {
  //       // scroll to anchor (hash)
  //       setTimeout(() => {
  //         // var productElement = this.$el.querySelector(`${to.hash}`);
  //         this.$emit("productElement", this.$el.querySelector(`${to.hash}`));
  //         var productOffset = this.productElement.offsetTop;
  //         return next(
  //           window.scrollTo({
  //             top: productOffset - 60,
  //             behavior: "smooth"
  //           })
  //         );
  //       }, 300);
  //     });
  //   }
  // },
  // mounted() {
  //   this.$nextTick(function() {
  //     this.$router.beforeEach((to, from, next) => {
  //       if (to.hash) {
  //         setTimeout(() => {
  //           var productElement = this.$el.querySelector(`${to.hash}`);
  //           var productOffset = productElement.offsetTop;
  //           return next(
  //             window.scrollTo({
  //               top: productOffset - 60,
  //               behavior: "smooth"
  //             })
  //           );
  //         }, 300);
  //       } else {
  //         next();
  //       }
  //     });
  //   });
  // }
};
</script>

<style lang="scss" scoped>
@import "@scss/color";
@import "@scss/default";

.swiper-pagination-bullet-active {
  background-color: #db8a86;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

path {
  fill: black !important;
}
.review-preview {
  iframe {
    width: 100%;
    height: 30vw;

    @include small {
      height: 50vw;
    }
  }
  .col {
    padding: 15px;
    background: $pink3;
  }
}

.review-menu {
  .menu-item {
    padding: 10px;

    > a {
      color: $white3;
      text-decoration: none;
      display: flex;
      flex-direction: column !important;
      // border-radius: 30px;
      border: 2px solid $dark2;
      padding: 8px 10px;
      color: $dark2;
      font-size: 15px;
      transition: 0.3s ease-out;
      text-align: center;

      &:hover {
        opacity: 0.5;
      }

      @include small {
        padding: 5px;
      }
    }
  }
}

.review-header {
  text-align: center;
  padding: 30px 0;

  .head {
    color: $pink1;
    font-size: 25px;
  }
}

.swiper-slide {
  // height: 200px;
  // padding-bottom: 70px;

  &:last-child {
    padding-bottom: 70px;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100% !important;
  }
}

.review-content {
  padding-top: 30px;
  padding-bottom: 30px;

  .content-wrapper {
    padding-bottom: 60px;
  }

  .content-title {
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 2px solid $pink1;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
